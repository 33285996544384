import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { SpecificChartFunctionality } from '../../constants/common'
import { decodeQueryString, encodeQueryString } from '../../utils/query_string'
import {
  DEFAULT_TYPES,
  FREE_TYPES_QUOTE,
  FREE_TYPES_QUOTE_VALUES,
  QuoteLongToShortType,
  QuoteNodeChartTypes,
  QuoteShortToLongType,
  QuoteUrlChartTypes,
  TypeBaseOption,
} from './utils'

export function useChartTypes({
  isPremium,
  specificFunctionality,
}: {
  isPremium: boolean
  specificFunctionality: SpecificChartFunctionality
}) {
  const [selectedChartTypeOverwrite, setSelectedChartTypeOverwrite] = React.useState<string>()
  const location = useLocation()
  const { b, t, ta, ty, p = 'd', r } = decodeQueryString(location.search)
  const taInt = ta ? Number.parseInt(ta) : 1
  const isQuoteOrQuoteFinancials = [
    SpecificChartFunctionality.quotePage,
    SpecificChartFunctionality.quoteFinancials,
  ].includes(specificFunctionality)

  const generateRouterPath = React.useCallback(
    (type: QuoteNodeChartTypes) => {
      if (isPremium || FREE_TYPES_QUOTE_VALUES.includes(type)) {
        return `${location.pathname}?${encodeQueryString({
          b,
          t,
          r,
          ty:
            type === QuoteNodeChartTypes.candlesticksimple
              ? QuoteLongToShortType.candlestick
              : QuoteLongToShortType[type],
          ta: type === QuoteNodeChartTypes.candlestick ? '1' : '0',
          p,
        })}`
      }
      return `/elite.ashx?${encodeQueryString({
        utm_source: 'finviz',
        utm_medium: 'banner',
        utm_campaign: 'quote-chart-type-select',
      })}`
    },
    [isPremium, location.pathname, b, t, r, p]
  )

  const chartTypes = React.useMemo(() => {
    const options = isPremium || !isQuoteOrQuoteFinancials ? DEFAULT_TYPES : FREE_TYPES_QUOTE

    return options.map((item) => ({
      ...item,
      href: generateRouterPath(item.value as QuoteNodeChartTypes),
      reloadDocument: !isPremium,
    })) as TypeBaseOption[]
  }, [generateRouterPath, isPremium, isQuoteOrQuoteFinancials])

  const currentType = React.useMemo(
    () =>
      chartTypes.find((type) => {
        if (selectedChartTypeOverwrite) return type.value === selectedChartTypeOverwrite

        const defaultType =
          ((ty as QuoteUrlChartTypes | undefined) && QuoteShortToLongType[ty as QuoteUrlChartTypes]) ??
          (FinvizQuoteTypeCurrent as QuoteNodeChartTypes)

        return !isPremium && defaultType === QuoteNodeChartTypes.candlestick && taInt === 0
          ? type.value === QuoteNodeChartTypes.candlesticksimple
          : type.value === defaultType
      }),
    [chartTypes, selectedChartTypeOverwrite, ty, isPremium, taInt]
  )

  return { currentType, chartTypes, setSelectedChartTypeOverwrite }
}
