import { Button, Time, TimeBreakpoint } from '@finviz/website'
import * as React from 'react'

import { useModelState } from '../../model-hooks/use-model-state'
import ChartLayout from '../../models/chart_layout'
import { FullscreenButton } from './fullscreen_button'
import { IdeasDropdown } from './ideas_dropdown'
import { ChartLayoutDropdown } from './layout_dropdown'
import { Publish } from './publish'
import { ThemeToggleButton } from './theme_toggle_button'
import { TourButton } from './tour_button'

const BREAKPOINTS: TimeBreakpoint[] = [
  { minWidth: -Infinity, marketBadgeText: false, timeFormat: 'timeOnly' },
  { minWidth: 550, marketBadgeText: false, timeFormat: 'short' },
  { minWidth: 700, marketBadgeText: false, timeFormat: 'long' },
  { minWidth: 768, marketBadgeText: true, timeFormat: 'long' },
]

interface ChartLayoutHeaderProps {
  model: ChartLayout
  onSettingsOpen: () => void
  hasPublish: boolean
}

export function ChartLayoutHeaderComponent({ model, onSettingsOpen = () => {}, hasPublish }: ChartLayoutHeaderProps) {
  const chartLayout = useModelState(model, { watchProperties: ['idea'] })!

  return (
    <>
      <div className="flex grow items-center space-x-2">
        <IdeasDropdown model={chartLayout} theme="headerItem">
          Ideas
        </IdeasDropdown>
      </div>
      <div className="flex grow items-center justify-end space-x-2">
        <TourButton page={model.specificChartFunctionality} theme="headerItem" className="mobile:hidden">
          Chart tour
        </TourButton>
        <ChartLayoutDropdown model={chartLayout} />
        <FullscreenButton />
        {hasPublish && (
          <Publish model={chartLayout} data-testid="chart-toolbar-publish" theme="headerItem">
            Share
          </Publish>
        )}
        <ThemeToggleButton model={chartLayout} />
        <Button
          appearance="square"
          leftContent="settingsWheel"
          theme="headerItem"
          data-testid="chart-settings-open-btn"
          title="Settings"
          className="settings-button"
          onClick={onSettingsOpen}
          disabled={!!chartLayout.idea?.id}
        />
        <Time
          id="time"
          breakpointMode="window"
          className="text-info text-gray-50 font-medium lg:text-2xs"
          breakpoints={BREAKPOINTS}
        />
      </div>
    </>
  )
}

export const ChartLayoutHeader = React.memo(
  ChartLayoutHeaderComponent,
  (prevProps, nextProps) =>
    prevProps.model === nextProps.model &&
    prevProps.onSettingsOpen === nextProps.onSettingsOpen &&
    prevProps.hasPublish === nextProps.hasPublish
)
