import { Options, SMAConfig } from '../overlays/configs/sma'
import { MainCalculation, OnValueCalculated } from './main'

type DefaultCalculatedValuesType = {
  sma: number[]
}

export class SMACalculation extends MainCalculation<Options, DefaultCalculatedValuesType> {
  static config = SMAConfig
  declare options: Options

  calculate(onValueCalculated?: OnValueCalculated<typeof this.calculatedValues>) {
    const { period } = this.options
    const { close } = this.quote

    this._calculatedValues = this.getDefaultCalculatedValues()

    if (close.length < period) return

    let sum = 0
    for (let i = 0; i < period; i++) {
      sum += close[i]
    }

    for (let i = period - 1; i < close.length; i++) {
      if (i >= period) sum = sum - close[i - period] + close[i]
      this.calculatedValues.sma[i] = sum / period

      if (onValueCalculated?.(this.calculatedValues, i) === false) {
        break
      }
    }
  }
}
