import { BBConfig, Options } from '../overlays/configs/bb'
import { MainCalculation, OnValueCalculated } from './main'

type DefaultCalculatedValuesType = {
  sma: number[]
  upperBand: number[]
  lowerBand: number[]
}

export class BbCalculation extends MainCalculation<Options, DefaultCalculatedValuesType> {
  static config = BBConfig

  calculate(onValueCalculated?: OnValueCalculated<typeof this.calculatedValues>) {
    const { period, deviations } = this.options
    const { close } = this.quote
    this._calculatedValues = this.getDefaultCalculatedValues()

    if (close.length < period) return

    let sum = 0
    const stdDev = []

    for (let i = 0; i < period; i++) {
      sum += close[i]
    }
    const initialPeriodIndex = period - 1
    for (let i = initialPeriodIndex; i < close.length; i++) {
      if (i > initialPeriodIndex) sum = sum - close[i - period] + close[i]
      this.calculatedValues.sma[i] = sum / period

      let stdDevSum = 0
      for (let j = i - period + 1; j <= i; j++) {
        stdDevSum += Math.pow(close[j] - this.calculatedValues.sma[i], 2)
      }
      stdDev[i] = Math.sqrt(stdDevSum / period)

      this.calculatedValues.upperBand[i] = this.calculatedValues.sma[i] + stdDev[i] * deviations
      this.calculatedValues.lowerBand[i] = this.calculatedValues.sma[i] - stdDev[i] * deviations

      if (onValueCalculated?.(this.calculatedValues, i) === false) {
        break
      }
    }
  }
}
