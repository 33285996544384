import {
  ButtonSizeType,
  CustomDateRange,
  TimeframeBar,
  useCustomDateRangeDialog,
  useFavoriteTimeframes,
  withSuspense,
} from '@finviz/website'
import React from 'react'

import { Instrument } from '../../../types/shared'
import { TIMEFRAME } from '../../constants/common'
import { useModelState } from '../../model-hooks/use-model-state'
import { isRedesignEnabled } from '../../utils'
import { useChartLayoutGlobalModelAsync } from '../../utils/useChartLayoutGlobalModelAsync'
import { useChartControls } from './use-chart-controls'
import { useChartModelCustomDateRangeValues } from './use-chart-model-custom-date-range-values'

const hasRedesign = isRedesignEnabled()

function StockDetailTimeframeBarRaw({
  chartIndex,
  size = 'regular',
  className = '!m-0 space-x-2',
  isDateRangeAvailable,
  isPremium,
  isLoadingOverride,
  isScrollIntoViewEnabled = false,
  onTimeframeSelect = () => {},
  onDateRangeSelect = () => {},
}: {
  chartIndex?: number
  size?: ButtonSizeType
  className?: string
  isDateRangeAvailable: boolean
  isPremium: boolean
  isLoadingOverride?: boolean
  isScrollIntoViewEnabled?: boolean
  onTimeframeSelect?: (timeframe: any) => void
  onDateRangeSelect?: (timeframe: any) => void
}) {
  const { chartLayoutModel } = useChartLayoutGlobalModelAsync()
  const chartModelUnwatched = React.useMemo(() => chartLayoutModel?.getAllCharts()[0] ?? null, [chartLayoutModel])
  const { favoriteTimeframes, toggleFavoriteTimeframe } = useFavoriteTimeframes(isPremium)
  const chartModel = useModelState(chartModelUnwatched, {
    watchProperties: ['timeframe', 'dateRange'],
  })
  const { instrument = Instrument.Stock } = chartModel?.quote() || {}
  const { isLoading } = useChartControls({
    chartModel,
    isInteractive: isDateRangeAvailable,
  })

  const {
    anchorDate,
    generateChartLayoutRouterPath,
    timeframeGroupsWithHref,
    dateRangeGroupsWithHref,
    isFetching,
    handleCustomDateRangeValidation,
  } = useChartModelCustomDateRangeValues({ chartModel, isPremium })
  const { onDialogSubmit, customDateRangeDialogState, handleDateRangeSelect, getDateRangeTimeframe } =
    useCustomDateRangeDialog({
      instrument,
      anchorDate,
      pathGenerator: generateChartLayoutRouterPath,
    })

  const dateRangeGroups = dateRangeGroupsWithHref({ isPremium, customRange: chartModel?.dateRange, instrument })
  const timeFrameGroups = timeframeGroupsWithHref(isPremium)
  const selectedTimeframe = chartModel?.timeframe ?? ''

  async function handleCustomRangeSubmit(values: { dateRange: string | null }) {
    const dateRangeTimeframe = values.dateRange
      ? getDateRangeTimeframe(values.dateRange)
      : { value: '', timeframe: chartModel!.timeframe }

    if (dateRangeTimeframe === null) {
      return
    } else if (dateRangeTimeframe.value) {
      const isValid = await handleCustomDateRangeValidation(dateRangeTimeframe)
      if (!isValid) {
        return
      }
    }

    onDialogSubmit(dateRangeTimeframe, onDateRangeSelect)
  }

  return (
    <>
      <TimeframeBar
        isPremium={isPremium}
        size={size}
        chartIndex={chartIndex}
        rounding={hasRedesign ? 'regular' : 'none'}
        timeFrameGroups={timeFrameGroups}
        dateRangeGroups={dateRangeGroups}
        selectedTimeframe={selectedTimeframe ?? ''}
        selectedDateRange={chartModel?.dateRange ?? null}
        favoriteTimeframes={favoriteTimeframes}
        loadingTimeframe={(isLoadingOverride ?? isLoading) ? selectedTimeframe : undefined}
        onDateRangeSelect={(value: any) => {
          handleDateRangeSelect(value)
          onDateRangeSelect?.(value)
        }}
        onCustomDateRangeClick={() => {
          if (isPremium) customDateRangeDialogState.show()
        }}
        onTimeframeSelect={onTimeframeSelect}
        onFavoriteTimeframeToggle={({ value }: { value: string }) => {
          toggleFavoriteTimeframe(value as TIMEFRAME)
        }}
        isDateRangeAvailable={isDateRangeAvailable}
        containerElement={document.body}
        className={className}
        isFavoritesEnabled={isPremium}
        isScrollIntoViewEnabled={isScrollIntoViewEnabled}
      />

      {chartModel && (
        <CustomDateRange
          dialogState={customDateRangeDialogState}
          dateRange={chartModel?.dateRange}
          anchorDate={anchorDate}
          onDialogSubmit={handleCustomRangeSubmit}
          isFetching={isFetching}
        />
      )}
    </>
  )
}

export const StockDetailTimeframeBar = withSuspense(StockDetailTimeframeBarRaw)
