import { Attrs, TRIXConfig } from '../indicators/configs/trix'
import { ExponentialMovingAverage } from './ema'
import { MainCalculation, OnValueCalculated } from './main'

type DefaultCalculatedValuesType = {
  trix: number[]
}

export class TrixCalculation extends MainCalculation<Attrs, DefaultCalculatedValuesType> {
  static config = TRIXConfig

  calculate(onValueCalculated?: OnValueCalculated<typeof this.calculatedValues>) {
    const { close } = this.quote
    const { period } = this.options
    const trippleEmaPeriod = period * 3 - 2
    this._calculatedValues = this.getDefaultCalculatedValues()

    if (close.length <= trippleEmaPeriod) return

    const ema1 = new ExponentialMovingAverage(period)
    const ema2 = new ExponentialMovingAverage(period)
    const ema3 = new ExponentialMovingAverage(period)

    let prev = 0
    let ema1Count = 0
    let ema2Count = 0
    let ema3Count = 0
    for (let index = 0; index < close.length; index++) {
      ema1.add(close[index])
      ema1Count += 1

      if (ema1Count < period) continue
      ema2.add(ema1.value)
      ema2Count += 1

      if (ema2Count < period) continue
      ema3.add(ema2.value)
      ema3Count += 1

      if (ema3Count < period) continue

      if (prev > 0) {
        this.calculatedValues.trix[index] = (ema3.value * 100) / prev - 100
      }
      prev = ema3.value

      if (onValueCalculated?.(this.calculatedValues, index) === false) {
        break
      }
    }
  }
}
