import Spine from '@finviz/spine'
import merge from 'lodash.merge'

import {
  ChartConfigChartPaneElement,
  CustomSpineEvents,
  IModalConfig,
  IModalConfigInput,
  ObjectHash,
  PaneArea,
  RequireByKey,
  Theme,
} from '../../types/shared'
import Line from '../canvas/line'
import Text, { ITextAttrs } from '../canvas/text'
import tailwindColors from '../constants/colors'
import {
  FONT_SIZE,
  INDICATOR_LABEL_HEIGHT,
  IndicatorType,
  OFFSET,
  PADDING,
  SpecificChartFunctionality,
  TextAlign,
  TextBaseline,
} from '../constants/common'
import { getTranslate } from '../controllers/renderUtils'
import math from '../helpers/math'
import Chart from '../models/chart'
import { ISettings } from '../models/chart_settings/interfaces'
import mouseModel from '../models/mouse'
import Pane from '../models/pane'
import Quote from '../models/quote'
import { getChartLayoutSettings } from '../models/settings'
import { isRedesignEnabled } from '../utils'
import { roundedRect } from '../utils/canvas-render'
import { renderCross } from '../utils/chart'
import { renderFadeExtendedHours, renderXAxis } from '../utils/chart-grid-render-utils'
import { getHEXWithSpecificAplha } from '../utils/colors'
import {
  drawInVisibleArea,
  getAreNoBarsVisible,
  getCompensatedFirstBarToRenderIndex,
  getVisibleBarToRenderIndex,
} from '../utils/draw_in_visible_area'
import { getIsSSr, getParsedIntegersFromPeriodString, isInRange, isPositiveInteger } from '../utils/helpers'
import IndicatorBaseConfig from './configs/indicatorBaseConfig'

const MAX_POSITIVE_DEFAULT_VALUE = Number.MAX_VALUE
const MAX_NEGATIVE_DEFAULT_VALUE = -Number.MAX_VALUE

const INDICATOR_DEFAULT_DOMAIN = (indicator: IndicatorType) => {
  switch (indicator) {
    case IndicatorType.Rvol:
      return { min: 0, max: 2 }
    case IndicatorType.Atr:
      return { min: 0, max: 10 }
    case IndicatorType.Adx:
    case IndicatorType.Aro:
    case IndicatorType.Mfi:
    case IndicatorType.Perf:
    case IndicatorType.Rmi:
    case IndicatorType.Rsi:
    case IndicatorType.Stofu:
    case IndicatorType.Ult:
      return { min: 0, max: 100 }
    case IndicatorType.Macd:
    case IndicatorType.Rwi:
    case IndicatorType.Trix:
      return { min: -1, max: 1 }
    case IndicatorType.Wr:
      return { min: -100, max: 0 }
    case IndicatorType.Aroosc:
    case IndicatorType.Roc:
      return { min: -100, max: 100 }
    case IndicatorType.Cci:
    case IndicatorType.Fi:
      return { min: -200, max: 200 }
    case IndicatorType.Shrtfl:
    case IndicatorType.Shrtra:
      return { min: 0, max: 1 }
    default:
      return { min: 0, max: 100 }
  }
}

export interface IIndicatorIsValid {
  getIsValid(key: string): boolean | void
}

class Indicator<Attrs extends ObjectHash = ObjectHash> extends Spine.Module implements IIndicatorIsValid {
  static config = IndicatorBaseConfig

  static initClass() {
    Object.defineProperty(this.prototype, 'height', {
      get() {
        return this.model.height
      },
    })
    Object.defineProperty(this.prototype, 'contentHeight', {
      get() {
        const { IndicatorSettings } = this.getChartLayoutSettings() as ISettings
        return this.height - IndicatorSettings.top.height - IndicatorSettings.bottom.height
      },
    })
  }

  static fromObject(values: ObjectHash, model: Pane) {
    const indicator = new this(values, model)
    indicator.set(values)
    return indicator
  }

  static getNumOfBarsBuffer({ period }: RequireByKey<ChartConfigChartPaneElement, 'period'>) {
    const [periodInt = 0] = getParsedIntegersFromPeriodString(period)
    return periodInt
  }

  declare data: Quote
  declare min: number
  declare max: number
  declare contentHeight: number
  declare height: number

  attrs: Attrs
  model: Pane
  labelWidth = 0
  fetchedAt = -1
  leftOffset = 0
  width = 0
  contentWidth = 0
  lastValue: number | null = null
  shouldUpdate = true
  tickers: string | null = null
  period?: string | number

  // Used to calculate yAxis min/max values if min === max, and we don't want to use domain defaults
  applyMinMaxPadding = false

  constructor(attrs: Attrs, model: Pane) {
    super(attrs, model)
    this.attrs = attrs
    this.model = model
    this.data = this.model.chart().quote()

    this.renderYAxis = this.renderYAxis.bind(this)
    this.renderCrossText = this.renderCrossText.bind(this)

    this.model.chart().bind('update', this.trigger.bind(this, 'change'))
    this.model.bind('update change', this.trigger.bind(this, 'change'))
    const mouseModelChangeHandler = this.trigger.bind(this, 'change', 'cross')
    this.model.bind('destroy', () => {
      mouseModel.unbind('change', mouseModelChangeHandler)
    })
    mouseModel.bind('change', mouseModelChangeHandler)

    this.model.chart().chart_layout().bind('theme', this.setupCache.bind(this))
  }

  get type() {
    return (this.constructor as typeof Indicator).config.type
  }

  get label() {
    return (this.constructor as typeof Indicator).config.label
  }

  get config() {
    return (this.constructor as typeof Indicator).config
  }

  render(context: CanvasRenderingContext2D) {
    const { IndicatorSettings } = this.getChartLayoutSettings()
    this.data = this.model.chart().quote()
    this.leftOffset = this.model.chart().leftOffset
    this.width = this.model.chart().width
    this.contentWidth = this.width - IndicatorSettings.left.width - IndicatorSettings.right.width

    this.compute()

    this.setupCache()

    this.renderYAxis(context)

    context.save()
    this.clip(context)
    this.renderXAxis(context)
    const translate = getTranslate({
      context,
      xOffset: this.leftOffset + IndicatorSettings.left.width,
      yOffset: IndicatorSettings.top.height,
    })
    translate.do()
    this.renderIndicator(context)
    translate.undo()

    renderFadeExtendedHours({ context, paneModel: this.model, quote: this.data })

    context.restore()

    if (!this.getIsChartPageSpecificFunctionality()) {
      this.renderLabel(context)
    }
  }

  isComputeNecessary() {
    // if quote was updated (this.fetchedAt !== this.data.fetchedAt)
    // or indicator setting was changed via modal (this.shouldUpdate)
    // allow compute and update variables to prevent another unless
    // those variables change again
    if (this.fetchedAt !== this.data.fetchedAt || this.shouldUpdate) {
      this.fetchedAt = this.data.fetchedAt
      this.shouldUpdate = false
      return true
    }
    return false
  }

  renderYAxis(context: CanvasRenderingContext2D, options?: { lastValueSuffix?: string }) {
    const { IndicatorSettings, ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    let y
    const translate = getTranslate({
      context,
      xOffset: IndicatorSettings.left.width,
      yOffset: IndicatorSettings.top.height,
    })
    translate.do()
    const ticks = this.model.scale.y.ticks(10)
    const tickTextStep = Math.abs(ticks[2] - ticks[0])
    const yLine = new Line(
      {
        x1: 0,
        x2: this.contentWidth,
        strokeStyle: Colors.grid,
        dashLength: 3,
      },
      this.model
    )
    const text = new Text(
      {
        x: this.contentWidth + OFFSET.M + IndicatorSettings.right.axis.margin.left!,
        font: Text.getMergedPropsWithDefaults('font', IndicatorSettings.right.axis.font),
        fillStyle: Colors.text,
        textBaseline: TextBaseline.middle,
      },
      this.model
    )
    for (let index = 0; index < ticks.length; index++) {
      const tick = ticks[index]
      y = Math.round(this.fy(tick))
      yLine.set({ y1: y, y2: y }).render(context)
      if (index % 2 === 0) {
        text.set({ text: this.formatAxis(tick, tickTextStep), y }).render(context)
      }
    }

    const minY = 0
    const maxY = this.contentHeight
    const valueY = this.lastValue === null ? 0 : Math.round(this.fy(this.lastValue))
    if (this.lastValue != null && valueY >= minY && valueY <= maxY) {
      new Text(
        {
          text: `${math.formatBigNumber(this.lastValue, 2)}${options?.lastValueSuffix ?? ''}`,
          x: this.contentWidth + OFFSET.M - PADDING.XXS + IndicatorSettings.right.axis.margin.left!,
          y: valueY,
          font: Text.getMergedPropsWithDefaults('font', IndicatorSettings.right.axis.font),
          lineHeight: IndicatorSettings.right.axis.font.lineHeight,
          padding: {
            top: IndicatorSettings.right.axis.font?.padding?.top ?? PADDING.XXS,
            right: IndicatorSettings.right.axis.font?.padding?.right ?? PADDING.XXS,
            bottom: IndicatorSettings.right.axis.font?.padding?.bottom ?? PADDING.XXS,
            left: IndicatorSettings.right.axis.font?.padding?.left ?? PADDING.XXS,
          },
          textBaseline: TextBaseline.middle,
          fillStyle: Colors.indicatorCurrentText,
          background: Colors.indicatorCurrentBackground,
        },
        this.model
      ).render(context)
    }

    translate.undo()
  }

  renderXAxis(context: CanvasRenderingContext2D) {
    const { IndicatorSettings } = this.getChartLayoutSettings()

    const translate = getTranslate({
      context,
      xOffset: IndicatorSettings.left.width,
      yOffset: IndicatorSettings.top.height,
    })

    translate.do()

    renderXAxis({ context, quote: this.data, paneModel: this.model, type: this.type })

    translate.undo()
  }

  renderLabel(context: CanvasRenderingContext2D) {
    if (this.getIsChartPageSpecificFunctionality()) {
      this.renderLabelChartsPage(context)
    } else {
      this.renderLabelQuotePage(context)
    }
  }

  renderLabelQuotePage(context: CanvasRenderingContext2D) {
    const { IndicatorSettings, ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const label = new Text(
      {
        text: this.toString(),
        x: IndicatorSettings.left.indicatorLabel.margin.left,
        y: IndicatorSettings.left.indicatorLabel.margin.top,
        font: Text.getMergedPropsWithDefaults('font', IndicatorSettings.left.indicatorLabel.font),
        fillStyle: Colors.text,
        textAlign: TextAlign.left,
        textBaseline: TextBaseline.top,
      },
      this.model
    )
    this.labelWidth = label.measure(context)
    return label.render(context)
  }

  renderLabelChartsPage(context: CanvasRenderingContext2D): { x: number; y: number; labelWidth: number } | void {
    const { IndicatorSettings } = this.getChartLayoutSettings()
    const { theme, specificChartFunctionality } = this.model.chart().chart_layout()
    const isQuoteOrQuoteFinancials = [
      SpecificChartFunctionality.quotePage,
      SpecificChartFunctionality.quoteFinancials,
    ].includes(specificChartFunctionality)
    const isDarkMode = theme === Theme.dark
    const x = isQuoteOrQuoteFinancials ? OFFSET.S : IndicatorSettings.left.indicatorLabel.margin.left!
    const y = IndicatorSettings.left.indicatorLabel.margin.top!
    const label = new Text(
      {
        text: this.toString(),
        x: x * 2,
        font: Text.getMergedPropsWithDefaults('font', IndicatorSettings.left.indicatorLabel.font),
        fillStyle: tailwindColors.gray[isDarkMode ? 50 : 900],
        textAlign: TextAlign.left,
        textBaseline: TextBaseline.middle,
      },
      this.model
    )
    this.labelWidth = label.measure(context) + IndicatorSettings.left.indicatorLabel.margin.left! * 2
    label.set({ y: y + (INDICATOR_LABEL_HEIGHT - label.attrs.lineHeight / 2) })
    roundedRect({
      ctx: context,
      x,
      y,
      height: INDICATOR_LABEL_HEIGHT,
      width: this.labelWidth,
      radius: 6,
      color: `${tailwindColors.gray[isDarkMode ? 700 : 50]}cc`,
    })
    label.render(context)

    return { x, y, labelWidth: this.labelWidth }
  }

  renderCross(context: CanvasRenderingContext2D) {
    renderCross({
      context,
      mouseModel,
      paneModel: this.model,
      quote: this.data,
      contentWidth: this.contentWidth,
      contentHeight: this.contentHeight,
      isIndicator: true,
      isPerfIndicator: this.type === IndicatorType.Perf || this.type === IndicatorType.Shrtfl,
      onRenderCrossText: !this.getIsChartPageSpecificFunctionality() ? this.renderCrossText : undefined,
    })

    if (this.getIsChartPageSpecificFunctionality()) {
      this.renderCrossText(context, mouseModel.getCrossIndexForPane(this.model))
    }
  }

  renderCrossText(context: CanvasRenderingContext2D, crossIndex: number) {
    this.data = this.model.chart().quote()
    const index = Number.isNaN(crossIndex) ? -1 : crossIndex
    if (this.getIsChartPageSpecificFunctionality()) {
      this.renderLabelChartsPage(context)

      this.renderCrossTextChartsPage(context, index)
    } else {
      this.renderCrossTextQuotePage(context, index)
    }
  }

  getValueLabelsAtIndex(_: number): Array<{ color: string; text: string | null }> {
    return []
  }

  renderCrossTextValueLabels(
    context: CanvasRenderingContext2D,
    crossIndex: number,
    { textBaseline, y }: Pick<ITextAttrs, 'textBaseline' | 'y'>
  ) {
    if (!context || isNaN(crossIndex) || crossIndex === -1) {
      return
    }

    const { ChartSettings, IndicatorSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const crossValueText = new Text(
      {
        font: Text.getMergedPropsWithDefaults('font', {
          ...IndicatorSettings.left.indicatorLabel.font,
          style: 'normal',
        }),
        textAlign: TextAlign.left,
        textBaseline,
        background: getHEXWithSpecificAplha(Colors.canvasFill, 0.8),
        padding: { top: 0, bottom: 0, left: 5, right: 5 },
      },
      this.model
    )

    let x = this.labelWidth + IndicatorSettings.left.indicatorLabel.margin.left! * 2

    const renderLabel = ({ color, text }: { color: string; text: string | null }) => {
      crossValueText.set({
        x,
        y,
        fillStyle: color,
        text: text ?? ' - ',
      })

      x += crossValueText.measure(context) + crossValueText.attrs.padding.left + crossValueText.attrs.padding.right

      crossValueText.render(context)
    }

    let lastRenderedIndex = -1
    this.getValueLabelsAtIndex(crossIndex).forEach(({ color, text }, index) => {
      if (text !== null) {
        if (lastRenderedIndex > -1) {
          renderLabel({ color: Colors.zeroChange, text: '·' })
        }
        renderLabel({ color, text })

        lastRenderedIndex = index
      }
    })
  }

  renderCrossTextChartsPage(context: CanvasRenderingContext2D, crossIndex: number) {
    const { indicatorLabel } = this.getChartLayoutSettings().IndicatorSettings.left
    // @todo - Default text lineheight is 20 , and is not overwritten by indicatorLabel.font (which is probably a bug) , in this case it's easier to hardcode this value than access is on Text instance
    const lineHeight = 20
    this.renderCrossTextValueLabels(context, crossIndex, {
      textBaseline: TextBaseline.middle,
      y: indicatorLabel.margin.top! + (2 * INDICATOR_LABEL_HEIGHT - lineHeight) / 2,
    })
  }

  renderCrossTextQuotePage(context: CanvasRenderingContext2D, crossIndex: number) {
    this.renderCrossTextValueLabels(context, crossIndex, {
      textBaseline: TextBaseline.top,
      y: this.getChartLayoutSettings().IndicatorSettings.left.indicatorLabel.margin.top,
    })
  }

  getValueLabel(value: number | undefined | null, unit = '') {
    if (value === undefined || value === null || !Number.isFinite(value)) return null

    return `${math.formatBigNumber(value, 2)}${unit}`
  }

  getOversoldOverboughtValueLabelsAtIndex(index: number, array: number[]) {
    const { IndicatorSettings } = this.getChartLayoutSettings()
    const dataIndex = this.data.barToDataIndex[index]
    return [{ color: IndicatorSettings.general.Colors.line, text: this.getValueLabel(array[dataIndex]) }]
  }

  renderOversoldOverbought(
    context: CanvasRenderingContext2D,
    array: number[],
    from: number,
    oversold: number,
    zero: number,
    overbought: number,
    drawLines?: boolean
  ) {
    const { IndicatorSettings } = this.getChartLayoutSettings()
    if (this.data.close.length === 0) return
    const drawInVisibleAreaProps = {
      fromIndexOffset: from,
      leftOffset: this.leftOffset,
      paneModel: this.model,
      quote: this.data,
      width: this.width,
    }

    const firstBarToRenderIndex = getCompensatedFirstBarToRenderIndex(drawInVisibleAreaProps)

    if (drawLines == null) {
      drawLines = true
    }

    context.save()

    let clipHeight = Math.round(this.fy(oversold) - this.fy(this.model.scale.y.domain()[1]))
    if (clipHeight <= 0 || getIsSSr()) {
      context.beginPath()
      context.rect(
        -this.leftOffset,
        this.fy(this.model.scale.y.domain()[1]) + 1, // compensation to addition of 1 in clip function
        this.contentWidth,
        clipHeight < 0 ? clipHeight - 1 : -1 // clipHeight - 1 is a compensation to addition of 1 in clip function ; fix bug in skia-canvas when clip is incorrect if height is 0 or clip is outside of previous clip https://github.com/samizdatco/skia-canvas/issues/87
      )
    }
    context.clip()
    context.set('fillStyle', '#87ceef')

    context.beginPath()
    const firstIndex = array.findIndex((value) => value !== undefined)
    const firstFx = this.fx(Math.max(firstBarToRenderIndex, firstIndex))
    context.moveTo(firstFx, Math.round(this.fy(this.model.scale.y.domain()[0])))
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i, x) => {
        if (array[i] !== undefined) context.lineTo(x, Math.round(this.fy(array[i])))
      },
    })
    context.lineTo(this.fx(this.data.close.length - 1), Math.round(this.fy(this.model.scale.y.domain()[0])))
    context.fill()
    context.restore()
    if (drawLines) {
      new Line(
        {
          x1: -this.leftOffset,
          x2: -this.leftOffset + this.contentWidth,
          y1: Math.round(this.fy(oversold)),
          y2: Math.round(this.fy(oversold)),
          strokeStyle: '#69c1ea',
        },
        this.model
      ).render(context)
    }

    if (drawLines) {
      this.renderZeroLine(context, zero)
    }

    context.save()
    clipHeight = Math.round(this.fy(overbought))
    if (clipHeight >= 0 || getIsSSr()) {
      context.beginPath()
      context.rect(
        -this.leftOffset,
        this.fy(this.model.scale.y.domain()[0]),
        this.contentWidth,
        clipHeight > 0 ? clipHeight : 1 // // fix bug in skia-canvas when clip is incorrect if height is 0 or clip is outside of previous clip https://github.com/samizdatco/skia-canvas/issues/87
      )
    }

    context.clip()
    context.set('fillStyle', '#dc9fe5')
    context.beginPath()
    context.moveTo(firstFx, Math.round(this.fy(this.model.scale.y.domain()[1])))
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i, x) => {
        if (array[i] !== undefined) context.lineTo(x, Math.round(this.fy(array[i])))
      },
    })
    context.lineTo(this.fx(this.data.close.length - 1), Math.round(this.fy(this.model.scale.y.domain()[1]) + 1))
    context.fill()
    context.restore()
    if (drawLines) {
      new Line(
        {
          x1: -this.leftOffset,
          x2: -this.leftOffset + this.contentWidth,
          y1: Math.round(this.fy(overbought)),
          y2: Math.round(this.fy(overbought)),
          strokeStyle: '#d386df',
        },
        this.model
      ).render(context)
    }

    context.set('strokeStyle', IndicatorSettings.general.Colors.line)
    context.set('fillStyle', '#dc9fe5')

    context.translate(0.5, 0.5)
    context.beginPath()
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i, x) => {
        if (array[i] !== undefined) context.lineTo(Math.round(x), Math.round(this.fy(array[i])))
      },
    })
    context.stroke()
    context.translate(-0.5, -0.5)
  }

  renderZeroLine(context: CanvasRenderingContext2D, zero: number) {
    new Line(
      {
        x1: -this.leftOffset,
        x2: -this.leftOffset + this.contentWidth,
        y1: Math.round(this.fy(zero)),
        y2: Math.round(this.fy(zero)),
        strokeStyle: '#ff8787',
        dashLength: 3,
      },
      this.model
    ).render(context)
  }

  setupCache() {}

  clip(context: CanvasRenderingContext2D) {
    const { IndicatorSettings } = this.getChartLayoutSettings()
    context.beginPath()
    context.rect(IndicatorSettings.left.width, IndicatorSettings.top.height, this.contentWidth, this.contentHeight + 1) // +1 shows one more pixel which is needed to be able to see a line at very bottom of an indicaor
    return context.clip()
  }

  formatAxis(value: number, tickStep?: number) {
    if (tickStep !== undefined && 10 > tickStep) {
      return value.toFixed(2)
    }
    return value.toString()
  }

  fx = (x: number) => this.model.scale.x(this.data.barIndex[x])

  fy = (y: number) => this.model.scale.y(y)

  set(values: Partial<Attrs>) {
    const { IndicatorSettings } = this.getChartLayoutSettings()
    this.shouldUpdate = true
    for (const key in values) {
      if (key !== 'type') {
        // @ts-expect-error - values keys don't have to always match variables on the instance
        this[key] = values[key]
      }
    }
    this.width = this.model.chart().width
    this.contentWidth = this.width - IndicatorSettings.left.width - IndicatorSettings.right.width

    const newAttrs = this.getModalConfig()
      .inputs.map(({ value }) => value.toString())
      .join(',')
    if (newAttrs) this.attrs = { ...this.attrs, period: newAttrs }

    this.model.trigger(CustomSpineEvents.ManualChange)
  }

  parsePeriodInt(values: Partial<Attrs>) {
    if (typeof values.period === 'string') {
      this.period = Number.parseInt(values.period, 10)
      this.model.trigger(CustomSpineEvents.ManualChange)
    }
  }

  setModel(model: Pane) {
    return (this.model = model)
  }

  isInArea(area: PaneArea) {
    const { IndicatorSettings } = this.getChartLayoutSettings()
    const x = IndicatorSettings.left.indicatorLabel.margin.left!
    const y = IndicatorSettings.left.indicatorLabel.margin.top!
    let width = OFFSET.M * 2 + this.labelWidth
    let height = OFFSET.S * 2 + FONT_SIZE.M

    if (this.getIsChartPageSpecificFunctionality()) {
      width = this.labelWidth
      height = INDICATOR_LABEL_HEIGHT
    }

    if (x < area.cursorX && area.cursorX < width + x && y < area.cursorY && area.cursorY < height + y) {
      return true
    }
    return false
  }

  getIsInChartView(_: Chart) {
    return true
  }

  moveBy() {}

  thumbsAreInArea() {
    return false
  }

  computeVisibleMinMax(...arrays: number[][]) {
    let min = MAX_POSITIVE_DEFAULT_VALUE
    let max = MAX_NEGATIVE_DEFAULT_VALUE

    const { leftOffset } = this.model.chart()

    const firstBarToRenderIndex = getVisibleBarToRenderIndex({
      quote: this.data,
      paneModel: this.model,
      leftOffset,
    })
    const lastBarToRenderIndex = getVisibleBarToRenderIndex({
      quote: this.data,
      paneModel: this.model,
      leftOffset,
      chartWidth: this.contentWidth,
    })
    const areNoBarsVisible = getAreNoBarsVisible(firstBarToRenderIndex, lastBarToRenderIndex)

    for (const arr of arrays) {
      for (let i = firstBarToRenderIndex.index; i <= lastBarToRenderIndex.index; i++) {
        if (!isNaN(arr[i])) {
          min = Math.min(min, arr[i])
          max = Math.max(max, arr[i])
        }
      }
    }

    if (!areNoBarsVisible && min !== MAX_POSITIVE_DEFAULT_VALUE && max !== MAX_NEGATIVE_DEFAULT_VALUE) {
      if (min === max && this.applyMinMaxPadding) {
        min = min - min * Math.sign(min)
        max = max + max * Math.sign(max)
      }
      if (min !== max) {
        return { min, max }
      }
    }
    return this.getDomainDefaults(this.type)
  }

  getModalConfig(): IModalConfig {
    return {
      title: this.label,
      inputs: [],
      inputsErrorMessages: {},
    }
  }

  getDomainDefaults = (indicatorType: IndicatorType) => INDICATOR_DEFAULT_DOMAIN(indicatorType)

  getMinMax() {
    this.data = this.model.chart().quote()
    this.compute()
    return { min: this.min, max: this.max }
  }

  toObject() {
    return merge({}, this.attrs, { type: this.type, tickers: this.tickers })
  }

  toConfig<T extends Indicator>(): T['attrs'] & { type: T['type'] } {
    return {
      type: this.type,
      period: this.getModalConfig()
        .inputs.map((input: IModalConfigInput) => input.value.toString())
        .join(','),
    }
  }

  toString() {
    return this.config.getShortLabelWithAttrs(this)
  }

  getChartLayoutSettings() {
    return getChartLayoutSettings(this.model.chart().chart_layout())
  }

  renderIndicator(_: CanvasRenderingContext2D) {
    throw Error('Implement renderIndicator method')
  }

  getIsValid(_: string): boolean | undefined {
    throw Error('Implement')
  }

  getIsNumberInputValid({ key, integerOnly = true }: { key: string; integerOnly?: boolean }): boolean {
    const input = this.getModalConfig().inputs.find(({ name }: { name: string }) => key === name)
    return !!input && isInRange(input) && (!integerOnly || isPositiveInteger(input.value))
  }

  getIsChartPageSpecificFunctionality() {
    const { specificChartFunctionality } = this.model.chart().chart_layout()
    const isCharts = specificChartFunctionality === SpecificChartFunctionality.chartPage
    const isRedesignedFuturesForexCrypto =
      isRedesignEnabled() &&
      [
        SpecificChartFunctionality.futuresPage,
        SpecificChartFunctionality.forexPage,
        SpecificChartFunctionality.cryptoPage,
        SpecificChartFunctionality.quotePage,
      ].includes(specificChartFunctionality)

    return isCharts || isRedesignedFuturesForexCrypto
  }

  getIsDrawing() {
    return false
  }

  getIsChartEvent() {
    return false
  }

  compute() {
    throw Error('Implement compute method')
  }
}

Indicator.initClass()

export default Indicator
