import { ButtonComponentProps, Icon, Select, SelectListProps } from '@finviz/website'
import * as React from 'react'

import { SpecificChartFunctionality } from '../../constants/common'
import { useChartTypes } from './use-chart-types'

interface Props extends Pick<ButtonComponentProps, 'theme' | 'size'> {
  isPremium: boolean
  specificFunctionality: SpecificChartFunctionality
  rounding: SelectListProps['rounding']
}

export function ChartTypesSelect({ isPremium, specificFunctionality, ...props }: Props) {
  const { chartTypes, currentType, setSelectedChartTypeOverwrite } = useChartTypes({ isPremium, specificFunctionality })

  return (
    <Select
      {...props}
      data-testid="chart-type-select"
      aria-label="Chart type"
      className="w-[7.75rem]"
      value={currentType}
      items={chartTypes}
      contentClass="truncate"
      leftContent={
        props.theme === 'chip' ? currentType?.icon : <Icon name={currentType?.icon} className="text-gray-300" />
      }
      nativeSelect="mobile"
      onChange={(item) => setSelectedChartTypeOverwrite(item.value)}
    />
  )
}
