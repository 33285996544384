import { ObjectHash } from '../../types/shared'
import Text from '../canvas/text'
import { ChartElementType, FONT_SIZE, LINE_HEIGHT, PADDING, TextAlign, TextBaseline } from '../constants/common'
import { getTranslate } from '../controllers/renderUtils'
import Pane from '../models/pane'
import Quote from '../models/quote'
import utils from '../utils'
import Chart, { BaseChartAttrs } from './base_chart'

interface MultiLineChartAttrs extends BaseChartAttrs {
  tickers: string[]
  label?: string
  min?: number
  max?: number
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

class MultiLineChart extends Chart<MultiLineChartAttrs> {
  static type = ChartElementType.MultiLineChart
  tickersDimensions: number[] = []

  constructor(values: Partial<MultiLineChartAttrs>, model: Pane) {
    super(values, model)
    this.renderText = this.renderText.bind(this)
  }

  renderChart() {
    const { ChartSettings, ColorsSettings } = this.getChartLayoutSettings()
    const translate = getTranslate({
      context: this.context,
      xOffset: this.leftOffset + ChartSettings.left.width + 0.5,
      yOffset: ChartSettings.top.height + 0.5,
    })
    translate.do()

    this.context.set('lineWidth', 1)

    const quotes: ObjectHash<Quote> = {}
    for (const quote of this.getQuotes()) {
      quotes[quote.ticker] = quote
    }

    for (let index = this.attrs.tickers.length - 1; index >= 0; index--) {
      const ticker = this.attrs.tickers[index]
      const quote = quotes[ticker]
      this.context.set('strokeStyle', ColorsSettings[index % ColorsSettings.length])
      this.context.beginPath()
      for (let i = 0, end = quote.close.length, asc = end >= 0; asc ? i < end : i > end; asc ? i++ : i--) {
        const x = this.fx(i)
        if (x + this.leftOffset < -10 || x + this.leftOffset > this.model.width + 10) {
          // margin?
          continue
        }
        const y = Math.round(this.fy(quote.close[i]))
        this.context.lineTo(x, y)
      }
      this.context.stroke()
    }

    translate.undo()
  }

  renderText(context: CanvasRenderingContext2D) {
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const text = this.attrs.label || ''
    const y = (this.height - 4) / 2
    new Text(
      {
        text,
        x: 28,
        y: ChartSettings.top.height + y,
        angle: -90,
        font: { size: 10, weight: '900', family: 'Lato, sans-serif' },
        fillStyle: Colors.textSecondary,
        textAlign: TextAlign.center,
        textBaseline: TextBaseline.alphabetic,
      },
      this.paneModel
    ).render(context)
    return this.renderCrossText(context, this.data.barToDataIndex.length - 1)
  }

  renderCrossText(context: CanvasRenderingContext2D, crossIndex: number) {
    const { ChartSettings, ColorsSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const maxTickerLength = utils.max(this.attrs.tickers.map((t) => t.length))

    const date = utils.dateFromUnixTimestamp(this.data.getDataByBarIndex('date', crossIndex) ?? 0)
    const month = date.getMonth() // 20141126
    const year = date.getFullYear()
    const day = date.getDate()
    const isLastDate = crossIndex === this.data.close.length - 1

    new Text(
      {
        text: `${months[month]} ${day} ${year}             `,
        x: ChartSettings.left.width + (maxTickerLength > 15 ? 200 : 132),
        y: 30,
        font: { size: 8 },
        fillStyle: Colors.text,
        background: Colors.canvasFill,
      },
      this.paneModel
    ).render(context)

    const quotes: ObjectHash<Quote> = {}
    for (const quote of this.getQuotes()) {
      quotes[quote.ticker] = quote
    }
    for (let index = 0; index < this.attrs.tickers.length; index++) {
      const y = PADDING.S + (PADDING.S + LINE_HEIGHT.M) * index
      const ticker = this.attrs.tickers[index]
      const closeValue = quotes[ticker].close[crossIndex]
      const text = new Text(
        {
          text: `${ticker} ${typeof closeValue === 'number' ? closeValue.toFixed(2) : NaN}  `,
          x: ChartSettings.left.width,
          y,
          font: { size: FONT_SIZE.M, style: 'bold' },
          lineHeight: LINE_HEIGHT.M,
          fillStyle: ColorsSettings[index % ColorsSettings.length],
          textAlign: TextAlign.left,
          textBaseline: TextBaseline.top,
        },
        this.paneModel
      )

      if (!this.tickersDimensions[index] && isLastDate) {
        this.tickersDimensions[index] = text.measure(context)
      }

      if (this.tickersDimensions[index]) {
        context.set('fillStyle', Colors.canvasFill)
        context.fillRect(
          ChartSettings.left.width - PADDING.XXXS,
          y - PADDING.XXXS,
          PADDING.XXXS + this.tickersDimensions[index] + PADDING.XXXS,
          PADDING.XXXS + FONT_SIZE.M + PADDING.XXXS
        )
      }

      text.render(context)
    }
  }

  computeVisibleMinMax(...arrays: Array<number[]>) {
    let min = Number.MAX_VALUE
    let max = Number.MIN_VALUE

    const { leftOffset } = this.model
    for (const arr of arrays) {
      for (let i = 0, end = arr.length, asc = end >= 0; asc ? i < end : i > end; asc ? i++ : i--) {
        const center = this.fx(i)
        if (center + leftOffset < 0 || center + leftOffset > this.width) {
          // margin?
          continue
        }

        if (min > arr[i]) {
          min = arr[i]
        }
        if (max < arr[i]) {
          max = arr[i]
        }
      }
    }

    return { min, max }
  }

  getMinMax() {
    const quotes = this.getQuotes()
    let { min, max } = this.computeVisibleMinMax(...quotes.map((q) => q.close))
    if (typeof this.attrs.min !== 'undefined') {
      min = this.attrs.min
    }
    if (typeof this.attrs.max !== 'undefined') {
      max = this.attrs.max
    }
    return { min, max }
  }

  renderVolumeAxis() {}

  renderVolume() {}

  getQuotes() {
    return Quote.select(
      (q: Quote) =>
        this.attrs.tickers.includes(q.ticker) &&
        q.timeframe === this.data?.timeframe &&
        this.model.chart_layout().isIdeaId(q.ideaID)
    )
  }

  toString() {
    return `Line chart ${this.data.ticker}`
  }
}

export default MultiLineChart
